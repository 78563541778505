import React, { useContext, useEffect, useState } from "react"
import { Link } from "components/anti/link/link"
// import { Accordion } from "components/anti/accordion/accordion"

import { Input } from "components/anti/forms/forms"
import {
  searchBlackIcon,
  chevronRight,
  arrowLeft,
  IconInstagram,
  IconFacebook,
  IconTiktok,
  IconTwitter,
  IconEmail,
  IconTele,
  IconWa,
  IconYt,
} from "components/icons"
import { NavContext } from "components/layout"
import {
  linkAbout,
  linkContact,
  linkFeedback,
  linkNewsAnalysis,
  linkSearch,
} from "components/page_url"
import { useRouter } from "next/router"

const MobileChildMenu = ({ navExpand, variant, topics, social }) => {
  const { state, setState } = useContext(NavContext)
  const [search, setSearch] = useState("")
  const router = useRouter()

  const handleEnterSearch = (e) => {
    if (e.key === "Enter" && e.target.value?.length > 2) {
      // router.push(`${linkSearch}?type=${search}`)
      window.location = `${linkSearch}?type=${search}`
    }
  }

  function closeAndBackNav(noReopen) {
    if (noReopen) {
      document.querySelector(".mobile-menu").classList.add("delay-0")
      document.querySelector(".menu-bg").classList.add("delay-0")
      document.querySelector(".mobile-menu").classList.remove("show")
      document.querySelector(".menu-bg").classList.remove("show")
      document.querySelector(".mobile-menu-child").classList.remove("show")      
      document.querySelector(".bottom-nav").classList.add("inactive")
      setTimeout(() => {
        document.querySelector(".mobile-menu").classList.remove("delay-0")
        document.querySelector(".menu-bg").classList.remove("delay-0")
      }, 1200)
    } else {
      document.querySelector(".mobile-menu-child").classList.remove("show")
      document.querySelector(".mobile-menu-back").classList.add("mobile-menu-socmed-hide");
      setTimeout(() => {
        setState({ pointedNavData: state?.navItemsData })
      }, 1900)
    }
  }

  const socmeds = {
    email: IconEmail,
    instagramUrl: IconInstagram,
    telegramUrl: IconTele,
    tiktokUrl: IconTiktok,
    twitterUrl: IconTwitter,
    whatsappUrl: IconWa,
    youtubeUrl: IconYt,
    facebookUrl: IconFacebook,
  }

  const keySocmed = Object.keys(social)
  const valueSocmed = Object.values(social)
  //
  const socmedList = keySocmed
    .map((i, ii) => {
      if (valueSocmed[ii] !== null)
        return {
          Icon: socmeds[i],
          name: keySocmed[ii],
          url: valueSocmed[ii],
        }
    })
    .filter((o) => o !== undefined)    

  return (
    <>
      <div className={`mobile-menu-child d-${navExpand}-none hide ${variant}`}>
        <div className="container overflow-hidden pt-4">
          <Input
            className="w-100 cak-input"
            iconLeft={searchBlackIcon}
            placeholder="Search topic or keyword"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={handleEnterSearch}
          />
        </div>

        <div className="mobile-menu-content">
          <div className="container mobile-menu-content-inside">
            <ul className="navbar-nav">
              {state?.pointedBranchNavData.map((item, i) => (
                <li key={`nav-branch-${i}`} className="nav-item">
                  <Link className={`nav-link branch-item`} to={item.url}>
                    <div
                      onClick={() => {
                        closeAndBackNav(true)
                        document.body.classList.remove("no-scroll")
                        document
                          .querySelector("html")
                          .classList.remove("no-scroll")
                          document.querySelector(".mobile-menu-back").classList.add("mobile-menu-socmed-hide");
                      }}
                    >
                      {item?.label}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="mobile-menu-socmed overflow-hidden position-fixed pb-1 mobile-menu-back mobile-menu-socmed-hide"
          onClick={() => closeAndBackNav()}
        >
          <div className={`d-flex align-items-center`}>
            <div className="back-btn-arrow mr-2">
              <img src={arrowLeft} />
            </div>
            <p className="m-0 font-semibold text-black font-semibold font-chakrapetch">
              BACK TO MENU
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileChildMenu

import React, { useContext, useEffect, useState } from "react"
import { Link } from "components/anti/link/link"
// import { Accordion } from "components/anti/accordion/accordion"

import { Input } from "components/anti/forms/forms"
import {
  searchBlackIcon,
  chevronRight,
  arrowLeft,
  IconInstagram,
  IconFacebook,
  IconTiktok,
  IconTwitter,
  IconEmail,
  IconTele,
  IconWa,
  IconYt,
} from "components/icons"
import { NavContext } from "components/layout"
import {
  linkAbout,
  linkContact,
  linkFeedback,
  linkNewsAnalysis,
  linkSearch,
} from "components/page_url"
import { useRouter } from "next/router"

const MobileMenu = ({ navExpand, variant, topics, social }) => {
  const { state, setState } = useContext(NavContext)
  const [search, setSearch] = useState("")
  const [isThisIphone, isThisIphoneSet] = useState(false);
  const router = useRouter()

  const handleEnterSearch = (e) => {
    if (e.key === "Enter" && e.target.value?.length > 2) {
      // router.push(`${linkSearch}?type=${search}`)
      window.location = `${linkSearch}?type=${search}`
    }
  }

  function closeAndBackNav(noReopen) {
    document.querySelector(".mobile-menu").classList.remove("show")
    setTimeout(() => {
      setState({ pointedNavData: state?.navItemsData })
    }, 1900)
    if (!noReopen) {
      setTimeout(() => {
        document.querySelector(".mobile-menu").classList.add("show")
      }, 2200)
    }
    if (noReopen) {
      setTimeout(() => {
        document.querySelector(".bottom-nav").classList.add("inactive")
      }, 2200)
    }
  }

  const socmeds = {
    email: IconEmail,
    instagramUrl: IconInstagram,
    telegramUrl: IconTele,
    tiktokUrl: IconTiktok,
    twitterUrl: IconTwitter,
    whatsappUrl: IconWa,
    youtubeUrl: IconYt,
    facebookUrl: IconFacebook,
  }

  const keySocmed = Object.keys(social)
  const valueSocmed = Object.values(social)
  //
  const socmedList = keySocmed
    .map((i, ii) => {
      if (valueSocmed[ii] !== null)
        return {
          Icon: socmeds[i],
          name: keySocmed[ii],
          url: valueSocmed[ii],
        }
    })
    .filter((o) => o !== undefined)

  const handleNav = () => {
    document.querySelector(".mobile-menu").classList.add("delay-0")
    document.querySelector(".menu-bg").classList.add("delay-0")
    document.querySelector(".mobile-menu").classList.remove("show")
    document.querySelector(".menu-bg").classList.remove("show")
    document.querySelector(".bottom-nav").classList.add("inactive")
    setTimeout(() => {
      document.querySelector(".mobile-menu").classList.remove("delay-0")
      document.querySelector(".menu-bg").classList.remove("delay-0")
    }, 1200)
  }

  return (
    <>
      <div className={`mobile-menu d-${navExpand}-none hide ${variant}`}>
        <div className="container overflow-hidden pt-4">
          <Input
            className="w-100 anim-1 cak-input"
            iconLeft={searchBlackIcon}
            placeholder="Search topic or keyword"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={handleEnterSearch}
          />
        </div>

        <div className="mobile-menu-content">
          <div className="container">
            <ul className="navbar-nav">
              {state?.pointedNavData.map((item, i) => (
                <li key={i} className="nav-item">
                  {!item?.branch ? (
                    !["About", "Contact Us", "Send Feedback"].includes(
                      item?.label
                    ) && (
                      <Link className={`nav-link anim-${i + 1}`} to={item?.url}>
                        <div
                          onClick={() => {
                            // closeAndBackNav(true)
                            document.body.classList.remove("no-scroll")
                            document
                              .querySelector("html")
                              .classList.remove("no-scroll")
                          }}
                        >
                          {item?.label}
                        </div>
                      </Link>
                    )
                  ) : (
                    <div
                      className={`nav-link anim-${i + 1}`}
                      onClick={() => {
                        const getBranchSource = [
                          { url: linkNewsAnalysis, label: "All News" },
                          ...topics.map((i) => {
                            return {
                              url: `${linkNewsAnalysis}/topic/${i?.slug}`,
                              label: i?.name,
                            }
                          }),
                        ]
                        document
                          .querySelector(".mobile-menu-child")
                          .classList.add("show")
                        setState({ pointedBranchNavData: getBranchSource })
                        document.querySelector(".mobile-menu-back").classList.remove("mobile-menu-socmed-hide");
                      }}
                    >
                      {item?.label}
                      <img className="ml-2 icon-nav-link" src={chevronRight} />
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <hr />
            <ul className="navbar-nav">
              <li className="nav-item nav-item-2">
                <Link
                  className="nav-link-2 anim-5"
                  to={linkAbout}
                  onClick={() => handleNav()}
                >
                  About
                </Link>
              </li>
              <li className="nav-item nav-item-2">
                <Link
                  className="nav-link-2 anim-6"
                  to={linkContact}
                  onClick={() => handleNav()}
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item nav-item-2">
                <Link
                  className="nav-link-2 anim-7"
                  to={linkFeedback}
                  onClick={() => handleNav()}
                >
                  Send Feedback
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mobile-menu-socmed position-fixed">
          <ul className="navbar-nav">
            {socmedList.map(({ Icon, url }, i) => (
              <li key={i} className={`nav-item`}>
                <Link
                  className={`nav-link anim-${i + 8 === 12 ? 11 : i + 8}`}
                  to={url}
                  target="_blank"
                >
                  <Icon fill="#2A66DA" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default MobileMenu

export const linkHome = "/"
export const linkNews = "/news"
export const linkNewsAnalysis = "/news-analysis"
export const linkSpecificTopic = "/news-analysis/topic"
export const linkInvestment = "/investment"
export const linkGlossary = "/glossary"
export const linkAbout = "/about"
export const linkFacilities = "/facilities"
export const linkContact = "/contact-us/join-us"
export const linkFeedback = "/contact-us/feedback"
export const linkJoinUs = "/contact-us/join-us"
export const linkTermCondition = "/term-condition"
export const linkPrivacyPolicy = "/privacy-policy"
export const linkSearch = "/search"

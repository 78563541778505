import React, { useEffect, useContext, useState } from "react"
import { useRouter } from "next/router"

import { Navbar, Footer, Loader } from "components/anti"
import { LoadingContext } from "context/loading-context"
import BottomNav from "./cak/bottom-nav"

import {
  linkAbout,
  linkContact,
  linkFeedback,
  linkGlossary,
  linkHome,
  linkInvestment,
  linkJoinUs,
  linkNews,
  linkSearch,
  linkTermCondition,
} from "./page_url"
import { useIsomorphicLayoutEffect } from "./hooks/hooks"
import { WrapperContext } from "context/wrapper-context"

import { Input } from "components/anti"
import { searchIcon } from "components/icons"

export const NavContext = React.createContext()

const Layout = ({
  children,
  noFooter = false,
  noBtnNavbar = false,
  hasSearch = false,
  hideMisc = false,
  isSticky = true,
}) => {
  const router = useRouter()

  const [showFooter, showFooterSet] = useState(false)

  const navigate = (to) => {
    router.push(to)
  }

  // Loader Context
  // allows loader only shown when directly visited via URL
  const { initialLoading } = useContext(LoadingContext)
  const { headerFooter } = useContext(WrapperContext)

  // Mobile viewport height workaround
  // prevent quirky behaviour on mobile when cover is set to 100vh
  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  // Transition link
  // for smooth transition effect
  useIsomorphicLayoutEffect(() => {
    const html = document.querySelector("html")
    const body = document.querySelector("body")
    body.classList.remove("no-scroll")
    html.classList.remove("no-scroll")

    const wrapper = document.querySelector("main")
    wrapper.classList.add("animated", "fadeIn")
  }, [])

  useEffect(() => {
    const wrapper = document.querySelector("main")
    const anchor = document.querySelectorAll("a")
    anchor.forEach((item) => {
      const navigation = (e) => {
        const redirect = item.getAttribute("href")
        const currentLoc = window.location.pathname
        e.preventDefault()
        if (currentLoc !== redirect) {
          wrapper.classList.remove("fadeIn")
          wrapper.classList.add("fadeOut")
          setTimeout(() => {
            navigate(redirect)
          }, 250)
        }
      }
      if (item.getAttribute("href") && !item.getAttribute("target")) {
        item.addEventListener("click", navigation)
      }
    })
    return () => {
      anchor.forEach((item) => {
        const navigation = (e) => {
          const redirect = item.getAttribute("href")
          const currentLoc = window.location.pathname
          e.preventDefault()
          if (currentLoc !== redirect) {
            wrapper.classList.remove("fadeIn")
            wrapper.classList.add("fadeOut")
            setTimeout(() => {
              navigate(redirect)
            }, 250)
          }
        }
        if (item.getAttribute("href") && !item.getAttribute("target")) {
          item.removeEventListener("click", navigation)
        }
      })
    }
  }, [])

  useEffect(() => {
    function scrollFuncFooter() {
      if (window.scrollY >= 100) {
        showFooterSet(true)
      } else {
        if (router.pathname !== linkHome) {
          showFooterSet(true)
        } else {
          showFooterSet(false)
        }
      }
    }

    if (typeof window !== undefined) {
      window.addEventListener("scroll", scrollFuncFooter)
    }

    if (router.pathname !== linkHome) {
      showFooterSet(true)
    }

    return () => window.removeEventListener("scroll", scrollFuncFooter)
  }, [])

  // CONTEXT
  //Define initialState
  const initialState = {
    isMobileMenuOpen: false,
    navItemsData: [
      {
        label: "Home",
        url: linkHome,
      },
      {
        label: "News & Analysis",
        url: "option",
        branch: [
          {
            label: "All News",
            url: linkNews,
          },
          {
            label: "Stocks",
            url: linkNews,
          },
          {
            label: "Index Funds",
            url: linkNews,
          },
          {
            label: "Cryptocurrency",
            url: linkNews,
          },
          {
            label: "Bonds",
            url: linkNews,
          },
          {
            label: "Analysis",
            url: linkNews,
          },
          {
            label: "IPO",
            url: linkNews,
          },
          {
            label: "Tax",
            url: linkNews,
          },
          {
            label: "Macro Economy",
            url: linkNews,
          },
        ],
      },
      {
        label: "Investment 101",
        url: linkInvestment,
      },
      {
        label: "Glossary",
        url: linkGlossary,
      },
      {
        label: "About",
        url: linkAbout,
      },
      {
        label: "Contact Us",
        type: "func",
        url: linkJoinUs,
        func: () => router.push(linkJoinUs)
      },
      {
        label: "Send Feedback",
        type: "func",
        url: linkFeedback,
        func: () => router.push(linkFeedback)
      },
    ],
    pointedNavData: [],
    pointedBranchNavData: [],
  }

  //Create reducer
  const reducer = (state, value) => {
    if (typeof value !== "object") {
      return state
    }
    return { ...state, ...value }
  }

  const [state, setState] = React.useReducer(reducer, initialState);
  const pathSplitted = router.asPath.split('/');
  useEffect(() => {
    if (!initialLoading) {
      if (pathSplitted.length > 2 ? !['contact-us', 'glossary'].includes(pathSplitted[1]) : !['contact-us'].includes(pathSplitted[1])) {
        document.querySelector('main').style.overflowX = 'initial'
        document.documentElement.classList.add("enable-sticky")

        const listSection = document.querySelectorAll("main > section")

        listSection?.forEach((item) => {
          item.classList.add("section-style")
        })
      }
    }
    return () => {
      document.documentElement.classList.remove("enable-sticky")
    }
  }, [initialLoading])

  return (
    <>
      <Loader variant="lottie" theme="dark" effect="slideOutUp" />
      <NavContext.Provider value={{ state, setState }}>
        {!initialLoading && !noBtnNavbar && <BottomNav />}
        <Navbar
          data={headerFooter?.headerFooter}
          topics={headerFooter?.nodes}
          hideMisc={hideMisc}
        />
      </NavContext.Provider>
      <main style={isSticky ? { overflowX: "initial" } : {}}>
        {/* <div className="grid-bg" /> */}
        {!initialLoading ? (
          <>
            {hasSearch ? (
              <div
                className={`container search-input-home mw-xl d-md-none pt-1`}
              >
                <Input
                  placeholder="Search topic or keyword"
                  iconLeft={searchIcon}
                  className="cak-input secondary"
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && e.target.value?.length > 2) {
                      router.push(`${linkSearch}?type=${e.target.value}`)
                    }
                  }}
                />
              </div>
            ) : null}

            {children}
            {!noFooter && showFooter ? (
              <Footer data={headerFooter?.headerFooter} />
            ) : null}
          </>
        ) : (
          <div className="h-vh-100 w-vw-100" />
        )}
      </main>
    </>
  )
}

export default Layout

// ░█▀▀▀ ░█▀▀█ ░█▀▀▀ ░█▀▀▀ 　 ▀▀█▀▀ ░█▀▀▀█ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█▀▀▀ ░█▄▄▀ ░█▀▀▀ ░█▀▀▀ 　 ─░█── ░█──░█ 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█─── ░█─░█ ░█▄▄▄ ░█▄▄▄ 　 ─░█── ░█▄▄▄█ 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be edited. However, please edit the styling on .footer-custom.scss.

// ========================================================================================================================================
// Footer
// ========================================================================================================================================

import React from "react"

import { Button, Image, Link, Social } from "components/anti"
import { ArrowUp, IconArrowRight } from "components/icons"
import iconEmail from "assets/img/icon/socmed/email.svg"
import iconFb from "assets/img/icon/socmed/facebook.svg"
import iconIg from "assets/img/icon/socmed/instagram.svg"
import iconLi from "assets/img/icon/socmed/linkedin.svg"
import iconTel from "assets/img/icon/socmed/telegram.svg"
import iconTt from "assets/img/icon/socmed/tiktok.svg"
import iconTw from "assets/img/icon/socmed/twitter.svg"
import iconWa from "assets/img/icon/socmed/whatsapp.svg"
import iconYt from "assets/img/icon/socmed/youtube.svg"
import { linkPrivacyPolicy, linkTermCondition } from "components/page_url"
import imgMiscTop from "assets/img/grfx/button-top-right.svg"
import imgMiscBottom from "assets/img/grfx/button-bottom-left.svg"

export const Footer = ({ data }) => {
  const socialLinks = [
    { icon: iconIg, title: "Instagram", url: data?.social?.instagramUrl },
    { icon: iconWa, title: "Whatsapp", url: data?.social?.whatsappUrl },
    { icon: iconTw, title: "Twitter", url: data?.social?.twitterUrl },
    { icon: iconEmail, title: "Email", url: `mailto:${data?.social?.email}` },
    { icon: iconTt, title: "Tiktok", url: data?.social?.tiktokUrl },
    { icon: iconYt, title: "Youtube", url: data?.social?.youtubeUrl },
    { icon: iconTel, title: "Telegram", url: data?.social?.telegramUrl },
  ]

  return (
    <footer>
      <section className="footer-main">
        <div className="container">
          <div className="row row-4">
            <div className="col-lg-3 order-lg-2 col-footer">
              <div className="footer-to-top">
                <div className="wrapper-button">
                  <Image src={imgMiscTop} className="img-misc misc-top" />
                  <Image src={imgMiscBottom} className="img-misc misc-bottom" />
                  <Button
                    variant="light"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    BACK TO TOP{" "}
                    <ArrowUp fill="#080808" width={21} height={21} />
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-lg-1 col-footer">
              <div className="footer-social">
                <h5>GET IN TOUCH</h5>
                <div className="list-socmed">
                  {socialLinks.map((item, i) => {
                    if (item.url) {
                      return (
                        <Link
                          className="socmed-item"
                          key={i}
                          to={item.url}
                          target="_blank"
                        >
                          <Image src={item.icon} alt={item.title} />
                        </Link>
                      )
                    }
                    return null
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-lg-0 col-footer">
              <div className="footer-address">
                <h5>ADDRESS</h5>
                <p>{data?.address?.text}</p>
              </div>
            </div>
            <div className="col-12 d-lg-none col-footer">
              <div className="footer-tnc-pp">
                <Link
                  to={linkTermCondition}
                  className="btn-link"
                  style={{ marginBottom: 18 }}
                >
                  Terms & Conditions <IconArrowRight fill="#FFC850" />
                </Link>
                <Link to={linkPrivacyPolicy} className="btn-link">
                  Privacy Policy <IconArrowRight fill="#FFC850" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Bottom */}
      <section className="footer-bottom">
        <div className="container">
          <div className="row w-100">
            <div className="col-md-6 col-left">
              <p>
                Copyright © 2022 CAK Investment Club.{" "}
                <br className="d-lg-none" />
                All rights reserved. Site by Antikode
              </p>
            </div>
            <div className="col-md-6 col-right">
              <Link to={linkTermCondition} className="btn-link">
                Terms & Conditions <IconArrowRight fill="#FFC850" />
              </Link>
              <Link to={linkPrivacyPolicy} className="btn-link">
                Privacy Policy <IconArrowRight fill="#FFC850" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

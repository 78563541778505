// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ========================================================================================================================================
// Loader
// ========================================================================================================================================

import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Lottie from "react-lottie"
import { motion } from "framer-motion"

import { LoadingContext } from "context/loading-context"

import logoDark from "assets/img/logo/cak-with-text.png"
import logoLight from "assets/img/logo/cak-with-text.png"
import loadingJSON from "assets/lottie/loading-desktop.json"

import yellowStar from "assets/img/grfx/sparks/spark-four-yellow.png"
import whiteStar from "assets/img/grfx/sparks/spark-small-2.png"

export const Loader = ({ theme, variant, className, effect }) => {
  const [loader, setLoader] = useState(false)
  const { initialLoading, setInitialLoading } = useContext(LoadingContext)

  useEffect(() => {
    if (initialLoading) {
      setLoader(true)
      setTimeout(() => {
        setInitialLoading((prevInitialLoading) => !prevInitialLoading)

        setTimeout(() => {
          setLoader(false)
        }, 500)
      }, 2500)
    }
  }, [setInitialLoading])

  const loaderVariant = () => {
    return `loader-${variant}`
  }

  const loaderTheme = () => {
    return `loader-${theme}`
  }

  return (
    <>
      {/* prettier-ignore */}
      <div className={`loader ${loaderTheme()} ${loaderVariant()} ${effect} ${className} ${!initialLoading ? `loader-exit ${!loader ? `d-none` : ``}` : ""}`}>
        {variant === "image" &&
          <div style={{ width: '100%', height: '100vh' }} className='d-flex flex-column justify-content-between'>
            <div style={{ width: 85, height: 35 }} className='py-3 d-lg-none ml-3'>
              <img src={`${theme === "dark" ? logoLight : logoDark}`} className="img-brand img-fluid" alt="Logo" />
            </div>
            <div style={{ width: 100, height: 41, marginLeft: 65 }} className='py-3 d-lg-block d-none'>
              <img src={`${theme === "dark" ? logoLight : logoDark}`} className="img-brand img-fluid" alt="Logo" />
            </div>

            <div className="pb-5 px-4 px-lg-4 ml-lg-5">
              {['LEARN', 'INVEST', 'REPEAT'].map((item, index) => (
                <div className="overflow-hidden position-relative" style={{ width: 'fit-content', paddingRight: 30 }} key={index}>
                  <motion.p
                    initial={{ y: 64, opacity: 0, scaleY: 1.8 }}
                    animate={{ y: 0, opacity: 1, scaleY: 1 }}
                    transition={{ duration: .5, delay: (index + 1) * .25, type: 'spring', bounce: 1, damping: 50, stiffness: 150 }}
                    className="text-64 font-semibold font-chakrapetch m-0"
                    style={{ lineHeight: '83px' }}
                  >
                    {item}
                  </motion.p>
                  {index === 1 &&
                    <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: .8, duration: .5 }} className="position-absolute" style={{ top: '26%', right: 0 }}>
                      <img src={whiteStar} style={{ transform: 'translateY(-30px)' }} width={12} height={12} />
                      <img src={yellowStar} className='spin' />
                    </motion.div>
                  }
                </div>
              ))}
            </div>
          </div>
        }
        {variant === "spinner" && <div className="img-spinner-wrapper"><div className="img-spinner" /></div>}
        {variant === "lottie" && (
          <>
            <div style={{ width: 85, height: 35 }} className='py-3 d-lg-none ml-3'>
              <img src={`${theme === "dark" ? logoLight : logoDark}`} className="img-brand img-fluid" alt="Logo" />
            </div>
            <div style={{ width: 100, height: 41, marginLeft: 65 }} className='py-3 d-lg-block d-none'>
              <img src={`${theme === "dark" ? logoLight : logoDark}`} className="img-brand img-fluid" alt="Logo" />
            </div>
            <div className="img-lottie" style={{ position: 'fixed', bottom: 0 }}>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: loadingJSON,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

Loader.propTypes = {
  variant: PropTypes.oneOf(["image", "spinner", "lottie"]),
  theme: PropTypes.oneOf(["dark", "light"]),
  effect: PropTypes.oneOf(["slideOutUp", "fadeOut"]),
  className: PropTypes.string,
}

Loader.defaultProps = {
  variant: "image",
  theme: "dark",
  effect: "fadeOut",
  className: "",
}

import React, { useContext } from "react"
// import { Link } from "components/anti/link/link"
import Link from "next/link"
import { Button } from "components/anti/buttons/buttons"
import {
  linkAbout,
  linkContact,
  linkFacilities,
  linkNews,
  linkNewsAnalysis,
  linkSearch,
} from "components/page_url"
import IconCloseSmall from 'assets/img/icon/misc/close-small.png';
import { NavContext } from "components/layout"
import { Input } from "components/anti/forms/forms"
import { IconClose, searchIcon } from "components/icons"
import { useRouter } from "next/router"
import sparksPng from "assets/img/grfx/submenu-dekstop-sparks.png"
// import { useRef } from "react"
import { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import yelloStar from "assets/img/grfx/sparks/spark-four-yellow.png"
import plusStar from "assets/img/grfx/sparks/spark-small-1.png"
import pixelStar from "assets/img/grfx/sparks/spark-small-2.png"
import { Helmet } from "react-helmet";

const DesktopMenu = ({
  navExpand,
  handleSearch,
  disabledSearch,
  data,
  topics = [],
}) => {
  const { state, setState } = useContext(NavContext)
  const router = useRouter()
  const [isSubmenuOpen, isSubmenuOpenSet] = useState(false)

  const [search, setSearch] = useState("")

  const handleEnterSearch = (e) => {
    if (e.key === "Enter" && e.target.value?.length > 2) {
      // router.push(`${linkSearch}?type=${search}`)
      window.location = `${linkSearch}?type=${search}`
    }
  }

  return (
    <>
      <div
        className={`desktop-menu justify-content-lg-between d-none d-${navExpand}-flex flex-fill`}
      >
        <ul className="navbar-nav w-100">
          {state?.navItemsData.map(
            (i, key) =>
              i?.label !== "Home" && (
                <li key={key} className="nav-item">
                  {i?.url === "option" && (
                    <p
                      onClick={() => isSubmenuOpenSet(true)}
                      className="m-0 d-flex align-items-center nav-link"
                    >
                      {i?.label}
                      <i className="air ai-chevron-down ml-2 text-cak-yellow" />
                    </p>
                  )}

                  {i?.type === "func" && (
                    <p
                      onClick={i?.func}
                      className="m-0 d-flex align-items-center nav-link"
                    >
                      {i?.label}
                    </p>
                  )}

                  {(i?.url !== "option" && i?.type !== "func") && (
                    <Link href={i?.url || "#"}>
                      <a className="nav-link">{i?.label}</a>
                    </Link>
                  )}
                </li>
              )
          )}

          <div className="nav-item-search pl-2 pr-5 d-flex align-items-center">
            <Input
              iconLeft={searchIcon}
              placeholder="Search topic or keyword"
              className="cak-input secondary w-100 with-clear"
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={handleEnterSearch}
              value={search}
              iconRight={IconCloseSmall}
              showIconRight={search !== ''}
              onClickRightIcon={() => setSearch('')}
              iconRightClassName='pointer'
            />
          </div>

          <div className="btn-join-mobile">
            <li className="d-flex flex-column justify-content-center">
              <div className="spark-grfx">
                <img className="yellow" src={yelloStar} />
                <img className="plus" src={plusStar} />
                <img className="pixel" src={pixelStar} />
              </div>
              <Link href={data?.button?.url}>
                <Button
                  className="btn-small px-3 text-14 bold-border"
                  variant="blue"
                >
                  {data?.button?.text}
                </Button>
              </Link>
            </li>
          </div>

          {/* <li className="nav-item">
            <Link href={data?.button?.url}>
              <Button variant="blue" className="bold-border">
                {data?.button?.text}
              </Button>
            </Link>
          </li> */}
        </ul>
      </div>

      <AnimatePresence>
        {isSubmenuOpen && (
          <>
            <Helmet>
              <body className="no-scroll" />
            </Helmet>
            <motion.div
              transition={{ bounce: 0, duration: 1 }}
              initial={{ x: "-100vw", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "-100vw", opacity: 0 }}
              className="d-none d-lg-flex big-submenu"
            >
              <motion.div
                transition={{ delay: 0.8 }}
                initial={{ y: 14, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 8, opacity: 0 }}
                className="big-submenu-close hover"
                onClick={() => isSubmenuOpenSet(false)}
              >
                <IconClose />
              </motion.div>

              <div className="container hide-scrollbar" style={{ overflowY: 'auto', height: '100%' }}>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <Link href={`${linkNewsAnalysis}`}>
                      <div className="hover font-chakrapetch text-24-44 font-semibold">
                        All News
                      </div>
                    </Link>
                  </div>
                  {topics.map((i) => (
                    <div key={i?.slug} className="col-lg-6 mb-5" onClick={() => isSubmenuOpenSet(false)}>
                      <Link href={`${linkNewsAnalysis}/topic/${i?.slug}`}>
                        <div className="hover font-chakrapetch text-24-44 font-semibold">
                          {i?.name}
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>

              <div className="sparks-submenu">
                <img className="img-full" src={sparksPng} />
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  )
}

export default DesktopMenu

/** Libs */

import { NavContext } from "components/layout"
import { useContext } from "react"

/** Global Components */

/** Local Components */

/** Assets */

/** Utils */

export default function BottomNav() {
  // State

  // Hooks
  const { state, setState } = useContext(NavContext)
  // Func

  // Use Effect

  return (
    <div className="bottom-nav d-xxl-none inactive">
      <button
        onClick={() => {
          const html = document.querySelector("html")
          const body = document.querySelector("body")
          document.querySelector(".bottom-nav").classList.toggle("inactive")
          document.querySelector(".mobile-menu").classList.toggle("show")
          document.querySelector(".menu-bg").classList.toggle("show")
          body.classList.toggle("no-scroll")
          html.classList.toggle("no-scroll")
          if (state?.isMobileMenuOpen) {
            setState({ isMobileMenuOpen: false })
            document
              .querySelector(".mobile-menu-child")
              .classList.remove("show")
            document.querySelector(".mobile-menu").classList.add("delay-0")
            document.querySelector(".menu-bg").classList.add("delay-0")
            document.querySelector(".mobile-menu-back").classList.add("mobile-menu-socmed-hide"); // close back btn
            setTimeout(
              () => {
                setState({
                  pointedNavData: state?.navItemsData,
                })
                document
                  .querySelector(".mobile-menu")
                  .classList.remove("delay-0")
                document.querySelector(".menu-bg").classList.remove("delay-0")
              },

              2200
            )
          } else {
            setState({ isMobileMenuOpen: true })
          }
        }}
        id="nav-icon"
        className="nav-icon nav-toggler absolute"
        // disabled={disabledMenu}
        // onClick={handleMenuMobile}
      >
        <span className="icon-bar top-bar" />
        <span className="icon-bar middle-bar" />{" "}
        <span className="icon-bar bottom-bar" />
      </button>
    </div>
  )
}
